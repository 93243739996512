<template>
  <div class="documents">
    <div class="header">
      <div class="page-info">
        <h4 class="heading-4">{{ $t("documentsPage.title") }}</h4>
        <p class="body-4">
          {{ $t("documentsPage.subtitle") }}
        </p>
      </div>

      <div>
        <h3 class="heading-3">{{ $t("documentsPage.newDocument") }}</h3>
        <NbButton
          icon="plus"
          data-toggle="modal"
          data-target="#modalImportDocument"
        >
          {{ $t("documentsPage.addDocument") }}
        </NbButton>
      </div>
    </div>

    <NbTablev2
      ref="tableDocuments"
      tableOf="documents"
      namespace="product_documents"
      :allFields="tableFields"
      :fields="fields"
      @reloadFields="fields = $event"
    >
      <template #cell(id)="data">
        {{ data.item.hs_code | category($t("others")) }}
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | dateFormat }}
      </template>

      <template #cell(actions)="data">
        <NbButton
          variant="tertiary"
          icon="download"
          @click="onDownload(data.item.doc_url)"
        >
          {{ $t("download") }}
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          data-toggle="modal"
          data-target="#modalDeleteDocument"
          @click="documentSelectedId = data.item.id"
        >
          {{ $t("delete") }}
        </NbButton>
      </template>
    </NbTablev2>

    <ModalImportDocument @success="onImportDocument" />

    <NbModal id="modalDeleteDocument" modalConfig="modal-dialog-centered">
      <template #header>
        <h4 class="heading-4">
          {{ $t("confirmDelete", { val: "" }) }}
        </h4>
      </template>

      <template #footer>
        <div class="d-flex justify-content-end">
          <NbButton variant="secondary" data-dismiss="modal">{{
            $t("cancel")
          }}</NbButton>
          <NbButton
            class="ml-2"
            :disabled="loading"
            @click="removeDocument()"
            >{{ $t("delete") }}</NbButton
          >
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbModal from "@/components/modal/NbModal.vue";

import { HS_CODES } from "@/constants";
import moment from "moment";
import ProductDocumentService from "@/services/ProductDocumentService";
import ModalImportDocument from "./components/Documents/ModalImportDocument.vue";

const categories = Object.entries(HS_CODES)
  .map(([name, key]) => ({ key, name }))
  .reduce((acc, current) => {
    acc[current.key] = current.name;
    return acc;
  }, {});

const productDocumentService = new ProductDocumentService();

export default {
  name: "Documents",
  components: {
    NbButton,
    NbTablev2,
    NbModal,
    ModalImportDocument,
  },
  filters: {
    category: (val, fallback) => {
      return categories[val] || fallback;
    },
    dateFormat(val) {
      return moment(val).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      loading: false,
      fields: [],
      documentSelectedId: null,
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "filename", label: this.$t("documentsPage.documentName") },
        { key: "id", label: this.$t("category") },
        { key: "hs_code", label: this.$t("HS code") },
        { key: "created_at", label: this.$t("addedIn") },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
        },
      ];
    },
  },
  methods: {
    async removeDocument() {
      try {
        this.loading = true;
        await productDocumentService.removeProductDocument(
          this.documentSelectedId
        );
        this.$refs.tableDocuments?.getData();
        this.$helpers.closeModal("modalDeleteDocument");
      } finally {
        this.loading = false;
      }
    },
    onDownload(docUrl) {
      window.open(docUrl, "_blank");
    },
    onImportDocument() {
      this.$refs.tableDocuments?.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.documents {
  .header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 680px) {
      flex-direction: row;
      justify-content: space-between;
      .page-info {
        max-width: 38.375rem;
      }
    }
  }
}
</style>
