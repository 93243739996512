<template>
  <NbModal id="modalImportDocument" modalConfig="modal-dialog-centered">
    <template v-slot:header>
      <h4 class="heading-4">
        {{ $t("documentsPage.addDocument") }}
      </h4>
    </template>

    <template v-slot:body>
      <form>
        <NbTextInput
          v-model="form.hscode"
          :name="$t('hsCode')"
          class="mb-4"
          id="hs_code"
          required
        />

        <NbDropFile
          v-model="form.file"
          :accept="['application/pdf']"
          :label="$t('documentsPage.addDocument')"
        />
      </form>
    </template>

    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("cancel") }}
        </NbButton>
        <NbButton
          class="ml-3"
          @click="uploadDocument()"
          :disabled="!isValid || loading"
        >
          {{ $t("dispatch") }}
        </NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import ProductDocumentService from "@/services/ProductDocumentService";

const productDocumentService = new ProductDocumentService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbDropFile,
  },
  name: "ModalImportDocument",
  data() {
    return {
      loading: false,
      form: {
        hscode: "",
        file: null,
      },
    };
  },
  computed: {
    isValid() {
      return !!(this.form.hscode.trim() && this.form.file);
    },
  },
  methods: {
    async uploadDocument() {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.form.file);
        formData.append("hs_code", this.form.hscode);

        await productDocumentService.createProductDocument(formData);
        this.form = {
          file: null,
          hscode: "",
        };

        this.$helpers.closeModal("modalImportDocument");

        this.$emit("success");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
