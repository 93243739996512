import HttpService from "./HttpService";

export default class ProductDocumentService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get product_document
   *
   * @param {*} id
   * @param filters
   * @returns {Promise}
   */
  getProductDocument(id = null, filters = {}) {
    return this.httpService.get("/v1/product_documents/" + id, {
      params: filters,
    });
  }

  /**
   * Get product_documents
   *
   * @param filters
   * @returns {Promise}
   */
  getProductDocuments(filters = {}) {
    return this.httpService.get("/v1/product_documents", {
      params: filters,
    });
  }

  /**
   * Download product_documents
   *
   * @param {*} filters
   * @returns {Promise}
   */
  downloadProductDocuments(filters = {}) {
    return this.httpService.get("/v1/product_documents/download", {
      params: filters,
    });
  }

  /**
   * Get imported product_document with errors
   *
   * @returns {Promise}
   */
  getRejectedProductDocuments() {
    return this.httpService.get("/v1/product_documents/import/errors");
  }

  /**
   * Get product_document contract
   *
   * @param {*} id
   * @returns {Promise}
   */
  getContract(id = null) {
    return this.httpService.get("/v1/product_documents/" + id + "/contract");
  }

  /**
   * Get product_document overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOverpack(id = null) {
    return this.httpService.get("/v1/product_documents/" + id + "/overpack");
  }

  /**
   * Get product_document overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getSalesChannel(id = null) {
    return this.httpService.get(
      "/v1/product_documents/" + id + "/saleschannel"
    );
  }

  /**
   * Get product_document checkpoints
   *
   * @param {*} id
   * @returns {Promise}
   */
  getCheckpoints(id = null) {
    return this.httpService.get("/v1/product_documents/" + id + "/checkpoints");
  }

  /**
   * Set product_document
   *
   * @param data
   * @returns {Promise}
   */
  createProductDocument(data = {}) {
    return this.httpService.post("/v1/product_document", data);
  }

  /**
   * Set product_document
   *
   * @param data
   * @returns {Promise}
   */
  setProductDocument(data = {}) {
    return this.httpService.post("/v1/product_documents", data);
  }

  /**
   * Update product_document
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateProductDocument(id = null, data = {}) {
    return this.httpService.put("/v1/product_documents/" + id, data);
  }

  /**
   * Delete product_document
   *
   * @param {*} id
   * @returns {Promise}
   */
  removeProductDocument(id = null) {
    return this.httpService.delete("/v1/product_documents/" + id);
  }

  /**
   * Import product_documents
   *
   * @param {*} data
   * @returns {Promise}
   */
  importProductDocuments(data) {
    return this.httpService.post("/v1/product_documents/import", data);
  }

  addCheckpoint(id = null, params) {
    return this.httpService.post(
      "/v1/product_documents/" + id + "/add-checkpoint",
      params
    );
  }
  //To print Labels, Commercial Invoice and Packing Slip in one PDF
  printingAll(ids) {
    return this.httpService.put("/v1/printings/all", ids);
  }
}
